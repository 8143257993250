import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getProducts } from '~/lib/handle-api'

import useLocale from '~/hooks/useLocale'

export default function useGetProducts(
  keys: string[],
  ids: string[],
  queryOptions?: UseQueryOptions<any, any, any>,
) {
  const locale = useLocale()

  return useQuery(
    [...keys, ...ids.map((id) => `${id}-${locale}`)],
    async () => {
      return await getProducts({ payload: { locale, ids } })
    },
    {
      enabled: ids?.length > 0,
      onError: () => {
        return { data: { products: [] } }
      },
      ...(queryOptions ?? {}),
    },
  )
}
